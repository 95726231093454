<template>
  <v-row justify="center">
    <v-dialog :value="true" max-width="600" @input="$emit('closeDialog')">
      <v-card class="pa-8">
        <!-- init step -->
        <v-row class="justify-end">
          <v-btn icon color="primary" @click="$emit('closeDialog')">
            <v-icon v-text="'close'" />
          </v-btn>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-1 px-3">
            <h2 class="d-block mb-4" v-text="$t('club.editClub.mergeWithOtherClub')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p v-text="$t('club.editClub.mergeWithOtherClubDisclaimer')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="showErrorMatchesStart">
            <v-alert type="error">
              {{ $t('club.editClub.mergeWithOtherErrorMatchesStart') }}
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="form.clubId" :items="clubs" outlined dense :label="$t('common.club')" :height="48"
              append-icon="mdi-chevron-down" :error-messages="getFieldErrors('clubId')" @blur="$v.form.clubId.$touch()"
            >
              <template #selection="{ item }">
                <div class="w-100">
                  <v-avatar>
                    <img :src="item.logo" class="pa-2">
                  </v-avatar>
                  {{ item.text }}
                </div>
              </template>
              <template #item="{ item }">
                <div class="w-100">
                  <v-avatar>
                    <img :src="item.logo" class="pa-2">
                  </v-avatar>
                  {{ item.text }}
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-card-actions class="pa-0">
          <v-row>
            <v-col cols="12" class="py-1 px-3 d-sm-flex justify-sm-center">
              <v-btn color="primary" class="w-100 w-sm-auto" :disabled="$v.form.$invalid" @click="mergeClub" v-text="$t('club.editClub.merge')" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'MergeWithOtherClubDialog',
  mixins: [formUtils],
  props: { organizationId: { type: String, default: null }, projectId: { type: String, default: null }, clubId: { type: String, default: null } },
  data: () => ({ form: { clubId: null }, showErrorMatchesStart: false }),
  computed: {
    ...mapGetters({ dbClubs: 'competition/clubs' }),
    ...mapGetters('competition', ['matches']),
    clubs: ({ dbClubs, clubId }) => dbClubs.filter(club => club.id !== clubId).map(club => ({ text: club.name, value: club.id, logo: club.logo })),
  },
  validations: { form: { clubId: { required } } },
  async created() {
    const { organizationId, projectId } = this
    if (!this.dbClubs.length) await this.$store.dispatch('competition/bindClubs', { organizationId, projectId })
    if (!this.matches.length) await this.$store.dispatch('competition/bindMatches', { organizationId, projectId })
  },
  methods: {
    async mergeClub() {
      if (!this.isFormValid()) return
      const { organizationId, projectId } = this
      const matchesStartedBaseClub = this.matches.filter(m => m.clubIds.find(c => c === this.clubId)).filter(m => m.status !== 'notStarted')
      const matchesStartedExtraClub = this.matches.filter(m => m.clubIds.find(c => c === this.form.clubId)).filter(m => m.status !== 'notStarted')
      if (matchesStartedBaseClub.length || matchesStartedExtraClub.length) {
        this.showErrorMatchesStart = true
        return
      }
      await this.runAsync(() => this.$store.dispatch('competition/mergeWithOtherClub', { organizationId, projectId, baseClubId: this.clubId, extraClubId: this.form.clubId }))
      this.$emit('closeDialog')
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input .v-select__selections {
  height: 54px;
}
</style>
